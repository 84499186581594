<template lang="pug">
AppPasswordResetForm(v-if="mail_token" :token="mail_token")
AppRequestForm(v-else)
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import AppRequestForm from "@/components/PasswordReset/AppRequestForm";
import AppPasswordResetForm from "@/components/PasswordReset/AppPasswordResetForm";

import { store } from "@/store";

export default {
  components: { AppRequestForm, AppPasswordResetForm },
  setup() {
    const route = useRoute();
    const mail_token = ref(null);

    onMounted(async () => {
      try {
        mail_token.value = route.query?.q;
        store.APP_LOADING = true;
      } finally {
        store.APP_LOADING = false;
      }
    });

    return {
      mail_token,
    };
  },
};
</script>
